import moment from 'moment'

function getFormattedString (str, dec, lang = 'ru') {
    let out = parseFloat(str).toFixed(dec)
    if (lang === 'ru') {
        out = out.replace(/\./g, ',')
        out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
    } else {
        out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return out
}

function getFormatedStockData (obj, lang = 'ru') {
    const ticker = {}
    let moexData = obj.data.filter(obj => obj.key === 'moex' && obj.data)
    let moexLastPrice = null

    if (moexData.length) {
        moexLastPrice = moexData.filter(obj => obj.method === 'get_tradings_stocks_full')[0].data.last_price
        moexData = moexData.filter(obj => obj.method === 'get_tradings_stocks_full_realtime')[0].data

        ticker.moex = {
            change: getFormattedString(moexData.last_price - moexLastPrice, 2, lang),
            percent: getFormattedString(String(((moexData.last_price - moexLastPrice) / moexLastPrice)*100), 2, lang) + '%',
            last: getFormattedString(moexData.last_price, 2, lang),
            date: moment(`${moexData.date} ${moexData.time}`, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm'),
            currency: moexData.currency_name,
            isNegative: moexData.last_price - moexLastPrice < 0,
            isPositive: moexData.last_price - moexLastPrice > 0
        }
        ticker.moex.percentIsNegative = ticker.moex.percent[0] === '-'
    }

    return ticker
}

export { getFormatedStockData }
