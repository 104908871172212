<template>
    <div class="ir-highlights__stocks u-bottom-margin--2xl">
        <div class="irp-stocks u-bottom-margin--m">
            <z-key-indicator
                size="xs"
                :value="MICEX.value"
                value-text="₽"
                :name="MICEX.changedPercent"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="MICEX.tooltip">
                            <span>{{ text.moex }}</span>
                        </z-tooltip>
                    </span>
                </template>
            </z-key-indicator>

            <!-- <z-key-indicator
                size="xs"
                :value="LSE.value"
                value-text="$"
                :name="LSE.change"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="LSE.tooltip">
                            <span>LSE</span>
                        </z-tooltip>
                    </span>
                </template>
            </z-key-indicator> -->

            <z-key-indicator
                size="xs"
                v-for="metal in Object.keys(metals)"
                :key="metal"
                :value="metals[metal].value"
                value-text="$"
                :name="metals[metal].change"
                :circle-color="metals[metal].circleColor"
                theme="stock"
            >
                <template v-slot:footer>
                    <span>
                        <z-tooltip icon-hidden placement="bottom" :content="metals[metal].tooltip">
                            <span>{{ metals[metal].key }}</span>
                        </z-tooltip>
                        {{ metals[metal].name }}
                    </span>
                </template>
            </z-key-indicator>
        </div>

        <z-link href="/investors/shareholders/stockchart/">
            <template #left>
                <z-icon name="round-arrow" dir="right" width="32" height="32" />
            </template>
            {{ text.tradingInf }}
        </z-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import moment from 'moment'
import axios from 'axios'
import { getFormatedStockData } from '@/utils/stockData.js'

export default {
    name: 'stocks',
    data () {
        return {
            metals: {
                Nickel: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#d1e5f3',
                    key: 'Ni',
                    name: localize({
                        ru: 'Никель',
                        en: 'Nickel'
                    })
                },
                Copper: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#ffeeb3',
                    key: 'Cu',
                    name: localize({
                        ru: 'Медь',
                        en: 'Copper'
                    })
                },
                Palladium: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#e5e8f0',
                    key: 'Pd',
                    name: localize({
                        ru: 'Палладий',
                        en: 'Palladium'
                    })
                },
                Platinum: {
                    value: '',
                    change: '',
                    tooltip: '',
                    circleColor: '#ebecec',
                    key: 'Pt',
                    name: localize({
                        ru: 'Платина',
                        en: 'Platinum'
                    })
                }
            },
            MICEX: {
                value: '',
                change: '',
                tooltip: '',
                changedPercent: ''
            },
            // LSE: {
            //     value: '',
            //     change: '',
            //     tooltip: ''
            // },
            lang: this.$root.lang,
            text: {
                tradingInf: localize({
                    ru: 'Информация о торгах',
                    en: 'Trading information'
                }),
                moex: localize({
                    ru: 'ММВБ',
                    en: 'MOEX'
                })
            }
            // polling: null
        }
    },
    async created () {
        this.loadMetalsStock()
        // this.loadStock()
        // this.pollData()
        this.loadMoex()
    },
    // beforeDestroy () {
    //     clearInterval(this.polling)
    // },
    methods: {
        // pollData () {
        //     this.polling = setInterval(this.loadStock, 300000)
        // },
        getFormattedString (str, dec) {
            let out = parseFloat(str).toFixed(dec)
            if (this.lang === 'ru') {
                out = out.replace(/\./g, ',')
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
            } else {
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            return out
        },
        // loadStock () {
        //     let LSEValue = this.getFormattedString('13.75', 2)
        //     let tooltipLSE

        //     if (this.lang === 'ru') {
        //         tooltipLSE = `Данные на дату закрытия 28.02.2022 г.`
        //     } else {
        //         tooltipLSE = `Close price as of February 28th`
        //     }

        //     this.LSE = {
        //         value: LSEValue,
        //         // change: LSEChange,
        //         tooltip: tooltipLSE
        //     }
        // },
        loadMetalsStock () {
            axios
                .get(`/local/templates/nornik/json/quotations.json`)
                .then((res) => {
                    let json = res.data

                    for (var key in json) {
                        let value = this.getFormattedString(json[key]['Bid'], 0)
                        let change = this.getFormattedString(json[key]['ChangePercent'], 2)
                        let tooltip

                        let dayOld = moment(json[key]['Date'].replace(/\s.*/g, ''), 'YYYY-MM-DD')

                        dayOld.subtract(1, 'days')
                        if (dayOld.format('E') === 7) {
                            dayOld.subtract(2, 'days')
                        }

                        if (this.lang === 'ru') {
                            tooltip = `Данные предоставлены по состоянию на ${dayOld.format(
                                'YYYY-MM-DD'
                            )}`
                        } else {
                            tooltip = `Market data delayed as ${dayOld.format('YYYY-MM-DD')}`
                        }

                        this.metals[key].value = value
                        this.metals[key].change = change
                        this.metals[key].tooltip = tooltip

                        // $.each($arStockCharts, function (index, el) {
                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find(`.stockchart__value`)
                        //         .eq(1)
                        //         .find('span')
                        //         .eq(0)
                        //         .html(value)

                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find(`.stockchart__delta`)
                        //         .removeClass(allClasses)
                        //         .addClass(newClass)
                        //         .html(change)

                        //     $(el)
                        //         .find(`[data-id="${key}"]`)
                        //         .find('.tooltip')
                        //         .tooltipster('content', tooltip)
                        // })
                    }

                    return json
                })
                .catch(function (error) {
                    console.error('Error: ' + error)
                })
        },
        async loadMoex () {
            const { lang } = this.$root
            const url = 'https://jsons.injector.3ebra.net/nornik-ru.json'
            let stocksResponse = await fetch(url).then(r => r.json())
            let stockData = getFormatedStockData(stocksResponse, lang)
            let tooltipMICEX

            if (this.lang === 'ru') {
                tooltipMICEX = `Данные предоставляются с&nbsp;задержкой как минимум на&nbsp;15 минут по&nbsp;состоянию на&nbsp;${stockData.moex.date} (GMT+03:00)`
            } else {
                tooltipMICEX = `Market data delayed by 15 minutes as ${stockData.moex.date} (GMT+03:00)`
            }

            this.MICEX = {
                ...this.MICEX,
                value: stockData.moex?.last,
                change: stockData.moex?.change,
                percentIsNegative: stockData.moex?.isNegative,
                changedPercent: stockData.moex?.percent.replace('%', ''),
                tooltip: tooltipMICEX
            }
        }
    }
}
</script>

<style lang="scss">
.irp-stocks {
    .z-key-indicator {
        &__circle {
            width: 60px !important;
            height: 60px !important;
        }

        @include breakpoint(tablet) {
            & + .z-key-indicator {
                margin-bottom: $token-spacers-xs;
            }
        }

        @include breakpoint(mobile) {
            &__circle {
                left: 30%;
            }

            &__footer {
                text-align: center;
            }

            &__body {
                align-items: center;
            }
        }

        @media screen and (max-width: 480px) {
            &__circle {
                left: 25%;
            }
        }

        @media screen and (max-width: 380px) {
            &__circle {
                left: 20%;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.irp-stocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    align-items: flex-end;

    .z-key-indicator {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;

        @include breakpoint(tablet) {
            width: 33.333%;
            flex-basis: 33.333%;
        }

        @include breakpoint(v-tablet) {
            width: 50%;
            flex-basis: 50%;
        }

        @include breakpoint(mobile) {
            width: 100%;
            flex-basis: 100%;
        }
    }
}

@media (max-width: 1359px) {
    .irp-stocks {
        justify-content: flex-start;
    }
}
</style>
